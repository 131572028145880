<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 xl9>
        <v-layout wrap justify-center>
          <v-flex xs12 sm7 lg7 xl7 pt-4 px-2>
            <v-layout wrap justify-center>
              <v-flex xs12 pt-2>
                <DatePicker v-bind:name="data" @stepper="winStepper" :key="counter" />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm5 lg5 xl5>
            <HolidayName v-bind:date="date" @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "./datePicker";
import HolidayName from "./holidayName";
export default {
  components: {
    DatePicker,
    HolidayName,
  },
  data() {
    return {
      date: new Date(),
      get: [],
      appLoading:false,
      ServerError: false,
      data:[],
      counter:0
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(windowData) {
      if (windowData.type == "holiday") {
        this.counter++
          this.data=windowData.name
        console.log(windowData);
      } else this.date = windowData.date;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/holiday/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.get = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
</style>